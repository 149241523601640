@import 'mixins.scss';
@import 'variables.scss';

.jl-application {
    font-family: $brandy;
    background-color: #f6f6f6;

    .container {
        color: #000000;
        font-size: 24px;
    }

    .required {
        color: $jl-red;   
        font-size:120%;
    }

    .required::after{        
        content: " *";
    }

    .header {
        color: #343737;
        text-align: left;
        font-size: 50px;
        margin-bottom: 18px;
        font-weight: bold;
        margin-top: 20px;
    }

    p {
        text-align: left;
        color: #000000;
        font-size: 17px;
        margin-bottom: 64px;
        font-weight: 500;
        font-style: normal;
        line-height: 30px;
    }

    .appSectionHeaders {
        border-bottom: 1px solid #979797;
        color: #343737;
        font-size: 30px;
        font-weight: bold;
        margin-top: 50px;
        margin-bottom: 15px;
    }

    label {
        color: #3A3A3A;
        letter-spacing: 3.75px;
        font-size: 12px;
        font-weight: 900;
        font-style: normal;
        text-transform: uppercase;
    }

    .instruction {
        color: #545454;
        font-size: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
        font-weight: 500;
        font-style: normal;
    }

    .instruction2 {
        color: #545454;
        font-weight: 500;
        font-style: normal;
    }

    .checkBoxContainer {
        background-color: white;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 13px;
        padding-top: 26px;
        padding-left: 30px;
        box-shadow: 0px 4px 0px #e8e8e8;
    }

    .cbcontainer {
        font-weight: 500;
        font-size: 24px;
        letter-spacing: 0px;
        text-transform: none;
    }

    .cb1Container {
        font-size: 24px;

        input[type=checkbox] {
            border-radius: 4px;
            background: #fff;
            border: 1px solid #979797;
            -webkit-appearance: none;
            height: 19px;
            width: 19px;
            padding: 0;
            margin: 0;
            position: relative;
            top: 3px;
            right: 3px;
            font-size: 15px;
            letter-spacing: 0px;

            &:checked {
                background: #862633;
                border: none;

                &:after {
                    font-family: 'Material Icons';
                    content: 'check';
                    color: #fff;
                    font-weight: bold;
                    position: relative;
                    left: 2px;
                    bottom: 1px;
                }
            }
        }
        /* The container2 */
        .cb2container {
            text-transform: none !important;

            input[type=checkbox] {
                border-radius: 4px;
                background: #fff;
                border: 1px solid #979797;
                -webkit-appearance: none;
                height: 18px;
                width: 18px;
                padding: 0;
                margin: 0;
                position: relative;
                top: 3px;
                right: 3px;

                &:checked {
                    background: #862633;
                    border: none;

                    &:after {
                        font-family: 'Material Icons';
                        content: 'check';
                        color: #fff;
                        font-size: 15px;
                        font-weight: bold;
                        position: relative;
                        left: 1px;
                        bottom: 2px;
                    }
                }
            }
        }

        .jl-application .form-group input {
            opacity: 0 !important;
        }

        .form-control {
            background-color: #f8f8f8;
            padding: 10px;
        }
    }

    .cb1Container {
        font-size: 24px;

        input[type=checkbox] {
            border-radius: 4px;
            background: #fff;
            border: 1px solid #979797;
            -webkit-appearance: none;
            height: 19px;
            width: 19px;
            padding: 0;
            margin: 0;
            position: relative;
            top: 3px;
            right: 3px;
            font-size: 15px;
            letter-spacing: 0px;

            &:checked {
                background: #862633;
                border: none;

                &:after {
                    font-family: 'Material Icons';
                    content: 'check';
                    color: #fff;
                    font-weight: bold;
                    position: relative;
                    left: 2px;
                    bottom: 1px;
                }
            }
        }
    }
    /* The container2 */
    .cb2container {
        text-transform: none !important;
        letter-spacing: 0px;
        font-weight: 500;
        font-size: 20px;

        input[type=checkbox] {
            border-radius: 4px;
            background: #fff;
            border: 1px solid #979797;
            -webkit-appearance: none;
            height: 18px;
            width: 18px;
            padding: 0;
            margin: 0;
            position: relative;
            top: 3px;
            right: 3px;

            &:checked {
                background: #862633;
                border: none;

                &:after {
                    font-family: 'Material Icons';
                    content: 'check';
                    color: #fff;
                    font-size: 15px;
                    font-weight: bold;
                    position: absolute;
                    left: 2px;
                    bottom: -11px;
                }
            }
        }
    }
    /* The container3 */
    .cb3container {
        text-transform: none !important;
        letter-spacing: 0px;
        font-weight: 500;
        font-size: 20px;

        input[type=checkbox] {
            border-radius: 4px;
            background: #fff;
            border: 1px solid #979797;
            -webkit-appearance: none;
            height: 18px;
            width: 18px;
            padding: 0;
            margin: 0;
            position: relative;
            top: 3px;
            right: 3px;

            &:checked {
                background: #862633;
                border: none;

                &:after {
                    font-family: 'Material Icons';
                    content: 'check';
                    color: #fff;
                    font-size: 15px;
                    font-weight: bold;
                    position: absolute;
                    left: 2px;
                    bottom: -3px;
                }
            }
        }
    }

    .jl-application .form-group input {
        opacity: 0 !important;
    }

    .form-control {
        background-color: #f8f8f8;
        padding: 10px;
    }

    button {
        display: block;
        background: $jl-red;
        color: #FFFFFF;
        border: none;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: 200;
        padding: 10px 30px;
        border-radius: 3px;
        font-size: 14px;
    }

    button[type=submit] {
        margin: 0 auto;
        margin-top: 80px;
        margin-bottom: 160px;
        width: 225px;
    }

    .form-control {
        &.multiselect-search {
            background: url('../images/magGlass.png') no-repeat;
            background-position: 96% 50%;
            background-size: 30px 30px;
            margin-bottom: 5px;
        }
    }

    .marketBox {
        border: solid 1px #ced4da;
        border-radius: .25rem;
        padding: 7px;
    }

    .maketState {
        height: 330px;
        overflow-y: scroll;

        li {
            list-style: none;
            margin-left: -25px;
        }

        .checkbox {
            vertical-align: text-top;
            font-weight: 500;
            font-style: normal;
        }

        .dropdown-menu.show {
            height: 300px;
            overflow-y: scroll;
            overflow-x: hidden;
            width: 100%;
        }

        button, .btn-group, .btn-group-vertical {
            width: 100%;
        }

        [class = "btn btn-default multiselect-clear-filter"] {
            display: none;
        }

        [class = "multiselect-container checkbox-list"] {
            height: 300px;
            overflow-y: scroll;
            overflow-x: hidden;
            width: 93%;
            border: 1px solid #979797
        }

        #possibilities {
            position: relative;
            top: 13px;
        }
    }

    input[type=checkbox] {
        border-radius: 4px;
        background: #fff;
        border: 1px solid #979797;
        -webkit-appearance: none;
        height: 18px;
        width: 18px;
        padding: 0;
        margin: 0;
        position: relative;
        top: 3px;
        right: 3px;
        margin-bottom: 0px !important;
        font-size: 15px;
        font-weight: bold;
        font-family: 'Material Icons';



        &:checked {
            background: #862633;
            border: 1px solid #862633;

            &:after {
                content: 'check';
                color: #fff;
                position: absolute;
                left: 0px;
                bottom: -3px;
            }
        }
    }


    .btnSubmit {
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 3px;
    }

    .transformnone {
        text-transform: none !important;
        font-size: 20px;
        line-height: 39px;
        font-weight: 500;
        letter-spacing: 0px;
    }

    .text-danger {
        font-weight: bold;
        font-size: 14px;
    }

    .labelBlack {
        color: black;
        font-weight: bold;
    }
}
