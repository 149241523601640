@import 'variables.scss';

.jl-HIWcandidateRequirements {
    background-color: #f8f8f8;
    font-family: $brandy;
    padding-top: 100px;

    @media(min-width:$md) {
        padding-top: 200px;
    }

    .subheader {
        font-size: 13px;
        color: #B09863;
        letter-spacing: 3px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
    }

    .header {
        font-size: 50px;
        color: #343737;
        font-weight: bold;
        margin-top: 2px;
        margin-bottom: 82px;
        text-align: center;
        line-height: 50px;
    }

    .nav-link-cta {
        background: $jl-red;
        border-radius: 3px;
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #FFFFFF;
        letter-spacing: 3px;
        text-decoration: none;
        width: 225px;
        margin: 0 auto;
        margin-top: 66px;
        margin-bottom: 80px;
    }

    .number {
        font-family: $dharma-bold;
        letter-spacing: 7px;
        color: #B09863;
        font-size: 800%;
        font-weight: bold;
        position: absolute;
        top: -110px;
    }

    .blockHeader {
        color: #000000;
        text-transform: uppercase;
        letter-spacing: 7px;
        font-size: 16px;
        font-weight: 800;
        font-style: normal;
        margin-top: 32px;
        margin-bottom: 12px;
    }

    .bullets {
        color: #515151;
        font-weight: 900;
        font-style: normal;
    }

    li::before {
        font-size: 20px;
        color: #B09863;
    }

    ul {
        color: #B09863;
        position: relative;
        list-style-image: url('/images/jlBullet.gif'); 
        margin-left: 0;
        padding-left: 1.2em;
        font-weight: bold;
    }

    ul li:before {
        font-family: 'Material Icons';
        position: absolute;
        left: 0px;
        margin-top: 3px;
        color: #B09863;
        font-size: 15px;
    }

    .bulletText {
        color: #515151;
    }

    .whiteBlockHolder {
        margin: 0 auto;
    }

    .whiteBlock {
        background-color: #FFFFFF;
        border: 10px solid #f8f8f8;
        padding: 20px;

        @media(max-width:$sm) {
            width: 80%;
            margin-left: 10%;
        }

        @media(max-width:$md) {
            width: 80%;
            margin-left: 10%;
        }
    }

    @media(max-width:$sm) {
        .subheader {
            font-size: 13px;
            color: #B09863;
            letter-spacing: 3px;
            font-weight: bold;
            margin-top: 16px;
            text-transform: uppercase;
            text-align: left;
        }

        .header {
            font-size: 50px;
            color: #343737;
            font-weight: bold;
            margin-top: 2px;
            text-align: left;
        }
    }

    @media(max-width:$md) {
        .row {
            display: grid;
        }
    }
}
