@import 'mixins.scss';
@import 'variables.scss';

body {
    font-family: $myriad;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    overflow-x: hidden;
    color: #fff;
    font-size: 10px;
    padding: 0px;
    height: 100%;
    background: #343737;
    font-size: 18px;
    font-weight: 200;
}

a, a:link, a:active, a:visited, a:hover {
    color: $jl-red;
    text-decoration: underline;
}

button:focus {
    outline:1px auto -webkit-focus-ring-color
}
