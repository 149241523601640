﻿/* Please keep in alphabetical order for easy organization! -TW */

@mixin background-size($background-size) {
    -webkit-background-size: $background-size;
    -moz-background-size: $background-size;
    -o-background-size: $background-size;
    background-size: $background-size;
}

@mixin input-placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}

@mixin linear-gradient($fromColor, $toColor) {
    background: opacify($toColor,1); /* Fallback Color */
    background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient($fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image: -moz-linear-gradient($fromColor, $toColor); /* FF3.6 */
    background-image: -ms-linear-gradient($fromColor, $toColor); /* IE10 */
    background-image: -o-linear-gradient($fromColor, $toColor); /* Opera 11.10+ */
    background-image: linear-gradient($fromColor, $toColor);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{opacify($fromColor,1)}', EndColorStr='#{opacify($toColor,1)}');
}

@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}
