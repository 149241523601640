@import 'mixins.scss';
@import 'variables.scss';
$slideHeightMobile: 140vh;
$slideHeightSmall: 140vh;
$slideHeightMedium: 105vh;
$slideHeightLarge: 140vh;

$OwnerSlideHeightMobile: 85vh;
$OwnerSlideHeightSmall: 85vh;
$OwnerSlideHeightMedium: 85vh;
$OwnerSlideHeightLarge: 85vh;

.waitForJS { display: none; }

.jl-slides {
    height: $slideHeightMobile;
    margin-bottom: 50px;


    @media(min-width: $sm) {
        height: $slideHeightSmall;
        margin-bottom: 20px;
        padding-bottom: 20px;
        padding-top: 0px;
    }

    @media(min-width: $md) {
        height: $slideHeightMedium;
    }

    @media(min-width: $lg) {
        height: $slideHeightLarge;
    }

    &::after {
        content: "";
        display: block;
        height: 40vh;
        width: 100%;
        background-image: linear-gradient(-180deg, rgba(52,55,55,0.00) 24%, #343737 95%);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
    }

    .slick-list {
        overflow-x: hidden;
    }

    .slick-slide {
        height: $slideHeightMobile;
        background-repeat: no-repeat;
        background-position: center;
        @include background-size(cover);

        @media(min-width: $sm) {
            height: $slideHeightSmall;
        }

        @media(min-width: $md) {
            height: $slideHeightMedium;
        }

        @media(min-width: $lg) {
            height: $slideHeightLarge;
        }


        .slide-inner {
            padding: 0 0px;
            height: 100%;
            margin: 0 auto;
            width: 70%;
            margin-top: 50px;

            @media(min-width: $sm) {
                margin-top: 120px;
            }

            @media(min-width: $md) {
                margin-top: 130px;
            }

            @media(min-width: $lg) {
                margin-top: 230px;
            }
        }

        .subheader {
            padding-top: 80px;

            @media(min-width: $md) {
                padding-top: 0px;
            }

            font-family: $brandy;
            font-weight: 900;
            font-size: 15px;
            text-transform: uppercase;
            letter-spacing: 4px;
            text-shadow: 5px 2px 6px rgba(0,0,0,0.27);
            margin-bottom: 16px;
        }

        .header {
            font-family: $dharma-bold;
            text-shadow: 5px 2px 6px rgba(0,0,0,0.27);
            font-size: 3.5em;
            line-height: .9;
            margin-bottom: 25px;

            @media(min-width: $md) {
                font-size: 6em;
                line-height: .8;
            }
        }

        p {
            font-family: $brandy;
            font-weight: 500;
            font-size: 1.25em;
            margin: 0;
            padding: 0;

            @media(min-width:$md) {
                width: 411px;
            }
        }

        .btn-slide {
            display: inline-block;
            color: #fff;
            background: #862633;
            border-radius: 5px;
            text-transform: uppercase;
            font-family: $brandy;
            font-weight: bold;
            font-size: 13px;
            letter-spacing: 2.08px;
            padding: 10px 26px;
            text-decoration: none;
            margin-top: 30px;
        }
    }

    @media(max-width:$sm) {
        .slick-prev {
            display: none !important;
        }

        .slick-next {
            display: none !important;
        }
    }

    .slick-prev,
    .slick-next {
        border: 0;
        width: 100px;
        height: 100px;
        top: calc(40% - 50px);
        z-index: 1;
        position: absolute;
        background: none;
        border: none;
        color: transparent;
        line-height: 0;
        font-size: 0;
        cursor: pointer;

        &:before {
            font-family: 'Material Icons';
            font-weight: 100;
            font-style: normal;
            font-size: 100px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: "liga" 1;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
            top: 0;
            border: 0;
            width: 100px;
            height: 100px;
            color: #fff;
        }
    }

    .slick-prev {
        left: 0;

        &:before {
            content: url('../images/icons/arrowLeftSimpleLineIcons.svg');
            height: 42px;
            width: 23px;
        }

        @media(min-width: $lg) {
            padding-left: 41px;
        }
    }

    .slick-next {
        right: 0;

        &:before {
            content: url('../images/icons/arrowRightSimpleLineIcons.svg');
            height: 42px;
            width: 23px;
        }

        @media(min-width: $lg) {
            right: 0px;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 620px;

        @media(min-width: $sm) {
            bottom: 420px;
        }

        @media(min-width: $md) {
            bottom: 320px;
        }

        @media(min-width: $lg) {
            bottom: 380px;
        }

        @media(max-height:800px) {
            bottom: 200px;
        }

        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;

        li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                background: 0 0;
                display: block;
                height: 8px;
                width: 8px;
                line-height: 0;
                font-size: 0;
                color: #D8D8D8;
                opacity: 0.5;
                padding: 5px;
                border: solid 1px #fff;
                background-color: #fff;
                border-radius: 50%;
                cursor: pointer;

                &:focus {
                    outline: 1px auto -webkit-focus-ring-color;
                }
            }

            &.slick-active button {
                opacity: 1;
            }
        }
    }
}

.jl-slidesOwner {
    /*height: $OwnerSlideHeightMobile;
    margin-bottom: 50px;*/


    /*@media(min-width: $sm) {
        height: $OwnerSlideHeightSmall;
        margin-bottom: 20px;
        padding-bottom: 20px;
        padding-top: 0px;
    }

    @media(min-width: $md) {
        height: $OwnerSlideHeightMedium;
    }

    @media(min-width: $lg) {
        height: 110vh;
        height: ($OwnerSlideHeightLarge);
    }*/
    /*&::after {
        content: "";
        display: block;
        height: 40vh;
        width: 100%;
        background-image: linear-gradient(-180deg, rgba(52,55,55,0.00) 24%, #343737 95%);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
    }*/
    .slick-list {
        overflow-x: hidden;
    }

    .slick-slide {
        height: $OwnerSlideHeightMobile;
        background-repeat: no-repeat;
        background-position: center;
        @include background-size(cover);

        @media(min-width: $sm) {
            height: $OwnerSlideHeightSmall;
        }

        @media(min-width: $md) {
            height: $OwnerSlideHeightMedium;
        }

        @media(min-width: $lg) {
            height: $OwnerSlideHeightLarge;
        }


        .slide-inner {
            padding: 0 0px;
            height: 100%;
            margin: 0 auto;
            width: 70%;
            margin-top: 50px;

            @media(min-width: $sm) {
                margin-top: 120px;
            }

            @media(min-width: $md) {
                margin-top: 130px;
            }

            @media(min-width: $lg) {
                margin-top: 230px;
            }
        }

        .subheader {
            padding-top: 80px;

            @media(min-width: $md) {
                padding-top: 0px;
            }

            font-family: $brandy;
            font-weight: 900;
            font-size: 15px;
            text-transform: uppercase;
            letter-spacing: 4px;
            text-shadow: 5px 2px 6px rgba(0,0,0,0.27);
            margin-bottom: 16px;
        }

        .header {
            font-family: $dharma-bold;
            text-shadow: 5px 2px 6px rgba(0,0,0,0.27);
            font-size: 3.5em;
            line-height: .9;
            margin-bottom: 25px;

            @media(min-width: $md) {
                font-size: 6em;
                line-height: .8;
            }
        }

        p {
            font-family: $brandy;
            font-weight: 500;
            font-size: 1.25em;
            margin: 0;
            padding: 0;

            @media(min-width:$md) {
                width: 411px;
            }
        }

        .btn-slide {
            display: inline-block;
            color: #fff;
            background: #862633;
            border-radius: 5px;
            text-transform: uppercase;
            font-family: $brandy;
            font-weight: bold;
            font-size: 13px;
            letter-spacing: 2.08px;
            padding: 10px 26px;
            text-decoration: none;
            margin-top: 30px;
        }
    }

    @media(max-width:$sm) {
        .slick-prev {
            display: none !important;
        }

        .slick-next {
            display: none !important;
        }
    }

    .slick-prev,
    .slick-next {
        border: 0;
        width: 100px;
        height: 100px;
        top: calc(40% - 50px);
        z-index: 1;
        position: absolute;
        background: none;
        border: none;
        color: transparent;
        line-height: 0;
        font-size: 0;
        cursor: pointer;

        &:before {
            font-family: 'Material Icons';
            font-weight: 100;
            font-style: normal;
            font-size: 100px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: "liga" 1;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
            top: 0;
            border: 0;
            width: 100px;
            height: 100px;
            color: #fff;
        }
    }

    .slick-prev {
        left: 0;

        &:before {
            content: url('../images/icons/arrowLeftSimpleLineIcons.svg');
            height: 42px;
            width: 23px;
        }

        @media(min-width: $lg) {
            padding-left: 41px;
        }
    }

    .slick-next {
        right: 0;

        &:before {
            content: url('../images/icons/arrowRightSimpleLineIcons.svg');
            height: 42px;
            width: 23px;
        }

        @media(min-width: $lg) {
            right: 0px;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 620px;

        @media(min-width: $sm) {
            bottom: 420px;
        }

        @media(min-width: $md) {
            bottom: 320px;
        }

        @media(min-width: $lg) {
            bottom: 380px;
        }

        @media(max-height:800px) {
            bottom: 200px;
        }

        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;

        li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                background: 0 0;
                display: block;
                height: 8px;
                width: 8px;
                line-height: 0;
                font-size: 0;
                color: #D8D8D8;
                opacity: 0.5;
                padding: 5px;
                border: solid 1px #fff;
                background-color: #fff;
                border-radius: 50%;
                cursor: pointer;

                &:focus {
                    outline: 1px auto -webkit-focus-ring-color;
                }
            }

            &.slick-active button {
                opacity: 1;
            }
        }
    }
}

