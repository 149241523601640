@import 'variables.scss';
.jl-HIWtheProcess {
    font-family: $brandy;
    height: 550px;
    width: 100%;
    background-color: #343737;

    @media(min-width: $sm) {
        height: 400px;
    }

    @media(min-width: $md) {
        height: 490px;
    }

    @media(min-width: $lg) {
        height: 300px;
    }

    &::after {
        /*content: "";*/
        display: block;
        height: 40vh;
        width: 100%;
    }

    .slick-slide {
        height: 550px;

        @media(min-width: $sm) {
            height: 470px;
        }

        @media(min-width: $md) {
            height: 454px;
        }

        @media(min-width: $lg) {
            height: 454px;
        }

        .subheader {
            margin-top: 180px;
            margin-bottom: 16px;
            font-size: 13px;
            color: #B09863;
            text-transform: uppercase;
            letter-spacing: 3px;
        }

        .header {
            margin-bottom: 22px;
            font-weight: bold;
            font-size: 44px;
            line-height: .8;
            margin-bottom: 22px;
        }

        p {
            font-size: 17px;
            line-height: 29px;
            font-weight: 500;
            font-style: normal;
            margin: 0;
            padding: 0;
        }
    }

    .slick-prev,
    .slick-next {
        border: 0;
        width: 100px;
        height: 100px;
        top: calc(50% - 50px);
        z-index: 1;
        position: absolute;
        background: none;
        border: none;
        color: transparent;
        line-height: 0;
        font-size: 0;
        cursor: pointer;

        &:before {
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 100px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: "liga" 1;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
            top: 0;
            border: 0;
            width: 100px;
            height: 100px;
            color: #fff;
        }
    }

    .slick-prev {
        left: 0;

        &:before {
            content: "keyboard_arrow_left";
        }
    }

    .slick-next {
        right: 0;

        &:before {
            content: "keyboard_arrow_right";
        }
    }

    .icons {
        text-align: center;
        width: 37px;
        height: 37px;
        margin: 0 auto;
        margin-bottom: 20px;
        background-color: #FAFAFA;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 70% 70%;
        border: 2px solid #FAFAFA;
        border-radius: 50%;
        padding: 18px;
    }

    @media(max-width:$sm) {
        $size: 40px;

        .slick-prev {
            border-bottom-right-radius: $size * 2;
            border-top-right-radius: $size * 2;
        }

        .slick-next {
            border-bottom-left-radius: $size * 2;
            border-top-left-radius: $size * 2;
        }

        .slick-prev::before {
            position: relative;
            right: 13px !important;
        }

        .slick-next::before {
            position: relative;
            right: 4px !important;
        }

        .slick-prev, .slick-next {
            height: $size * 2;
            width: $size;
            top: calc(50% - 50px);
            z-index: 1;
            position: absolute;
            background-color: #B09863;
            color: transparent;
            line-height: 0;
            font-size: 0;
            cursor: pointer;
            margin: 0;

            &:before {
                font-family: 'Material Icons';
                font-weight: 100;
                font-style: normal;
                font-size: 50px;
                line-height: 1;
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                font-feature-settings: "liga" 1;
                -webkit-font-feature-settings: 'liga';
                -webkit-font-smoothing: antialiased;
                top: -5px;
                border: 0;
                width: 40px;
                height: 40px;
                color: #fff;
            }
        }
    }
}

.jl-HIWtheProcessHeader {
    font-family: $brandy;

    .subheader {
        font-size: 13px;
        margin-top: 80px;
        margin-bottom: 16px;
        letter-spacing: 3px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .header {
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 47px;
    }

    $inactive-bgcolor: #b09863;
    $active-bgcolor: #fff;
    $arrow-size: 24px;

    body {
        background: #343737;
    }

    .ladder {
        margin-top: 47px;
        min-height: 130px;
        width: 290px;
        margin: 0 auto;

        @media(min-width:$sm) {
            width: 430px;
        }

        @media(min-width:$md) {
            width: 315px;
        }

        @media(min-width:$lg) {
            width: 735px;
        }
    }

    *, ::after, ::before {
        box-sizing: content-box;
    }

    ul li {
        list-style: none;

        .rung h3 {
            font-size: 13px;
        }

        .rung {
            display: block;
            float: left;
            height: 34px;
            background: $inactive-bgcolor;
            text-align: center;
            padding: 14px 6px 0 6px;
            position: relative;
            margin: 0 28px 0 0;
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
            color: #fff;

            @media(min-width:$sm) {
                padding: 14px 15px 0px 15px;
                margin: 0 28px 0 0;
            }

            @media(min-width:$md) {
                padding: 14px 10px 0px 10px;
                font-size: 13px;
            }

            @media(min-width:$lg) {
                padding: 14px 15px 0px 15px;
            }
        }

        .rung:after {
            content: "";
            border-top: $arrow-size solid transparent;
            border-bottom: $arrow-size solid transparent;
            border-left: $arrow-size solid $inactive-bgcolor;
            position: absolute;
            right: -$arrow-size;
            top: 0;
        }

        .rung:before {
            content: "";
            position: absolute;
            margin-top: -14px;
            border-width: $arrow-size 0 $arrow-size $arrow-size;
            border-style: solid;
            border-color: $inactive-bgcolor $inactive-bgcolor $inactive-bgcolor transparent;
            left: -$arrow-size;
        }

        &.selected {
            .rung {
                background: $active-bgcolor;
                color: #343737;
            }

            .rung:after {
                border-left: $arrow-size solid $active-bgcolor;
            }

            .rung:before {
                border-color: $active-bgcolor $active-bgcolor $active-bgcolor transparent;
            }

            .rung a {
                text-decoration: none;
                color: black;
                position: relative;
                top: 2px;
            }
        }

        .rung a {
            text-decoration: none;
            color: #ffffff;
            position: relative;
            top: 2px;
        }
    }

    ul li:first-child .rung:before {
        display: none;
    }
    /* Add Border Radius */
    ul li:first-child .rung {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 1px;
    }

    span [class="d-block d-lg-none"] {
        position: relative;
        left: 5px;
        bottom: 8px;
        font-family: $dharma;
        font-size: 25.3px;
        font-weight: 500;
    }
}
