@import 'mixins.scss';
@import 'variables.scss';

.jl-shellFamily {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../images/old-shell-truck.jpeg') no-repeat;
    background-size: 230% 130%;
    background-position: 30% 50%;
    text-align: center;
    padding: 0 26px;

    .shellLogo {
        margin-top: 92px;
        margin-bottom: 23px;
        text-align: center;

        @media(min-width:$md) {
            margin-top: 111px;
        }
    }

    .familyText {
        font-family: $brandy;
        font-weight: bold;
        font-style: normal;
        font-size: 13px;
        color: #FFFFFF;
        letter-spacing: 2.17px;
        margin-bottom: 15px;
    }

    .header {
        font-family: $brandy;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 92px;
    }

    p {
        margin-bottom: 36px;
        font-family: $brandy;
        font-weight: 500;
        font-style: normal;

        @media(min-width:$md) {
            margin-bottom: 122px;
        }
    }

    .col-12 {
        padding-left: 0;
        padding-right: 0
    }

    .col-4 {
        padding-left: 0;
    }

    .col-md-6 {
        padding-left: 0;
    }


    @media(min-width:$sm) {
        background-size: 230% 100%;
        background-position: 30% 50%;
    }

    @media(min-width:$md) {
        background-size: 230% 100%;
        background-position: 30% 50%;
    }

    @media(min-width:$lg) {
        background-size: 100% 100%;
        background-position: 30% 50%;
    }
}