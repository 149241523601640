@import url("https://hello.myfonts.net/count/2c4415");


@font-face {
    font-family: 'DharmaGothicM-ExBold';
    src: url('../fonts/2C4415_0_0.eot');
    src: url('../fonts/2C4415_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2C4415_0_0.woff') format('woff'),url('../fonts/2C4415_0_0.ttf') format('truetype');
}


@font-face {
    font-family: 'DharmaGothicM-Regular';
    src: url('../fonts/2C4415_1_0.eot');
    src: url('../fonts/2C4415_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2C4415_1_0.woff') format('woff'),url('../fonts/2C4415_1_0.ttf') format('truetype');
}


@font-face {
    font-family: 'DharmaGothicM-Bold';
    src: url('../fonts/2C4415_2_0.eot');
    src: url('../fonts/2C4415_2_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2C4415_2_0.woff') format('woff'),url('../fonts/2C4415_2_0.ttf') format('truetype');
}


@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=90261650-80bc-453a-a4de-44bc8c050b52");

@font-face {
    font-family: "Neue Helvetica W01_n4";
    src: url("../fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix") format("eot")
}

@font-face {
    font-family: "Neue Helvetica W01";
    src: url("../fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix");
    src: url("../fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix") format("eot"),url("../fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff") format("woff"),url("../fonts/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.ttf") format("truetype"),url("../fonts/da09f1f1-062a-45af-86e1-2bbdb3dd94f9.svg#da09f1f1-062a-45af-86e1-2bbdb3dd94f9") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Neue Helvetica W01_n5";
    src: url("../fonts/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix") format("eot")
}

@font-face {
    font-family: "Neue Helvetica W01";
    src: url("../fonts/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix");
    src: url("../fonts/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix") format("eot"),url("../fonts/60be5c39-863e-40cb-9434-6ebafb62ab2b.woff") format("woff"),url("../fonts/4c6503c9-859b-4d3b-a1d5-2d42e1222415.ttf") format("truetype"),url("../fonts/36c182c6-ef98-4021-9b0d-d63122c2bbf5.svg#36c182c6-ef98-4021-9b0d-d63122c2bbf5") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Neue Helvetica W01_n7";
    src: url("../fonts/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix") format("eot")
}

@font-face {
    font-family: "Neue Helvetica W01";
    src: url("../fonts/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix");
    src: url("../fonts/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix") format("eot"),url("../fonts/14ff6081-326d-4dae-b778-d7afa66166fc.woff") format("woff"),url("../fonts/8fda1e47-19be-46c7-8d83-8d4fb35572f0.ttf") format("truetype"),url("../fonts/f751c8ae-1057-46d9-8d74-62592e002568.svg#f751c8ae-1057-46d9-8d74-62592e002568") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Neue Helvetica W01_o3";
    src: url("../fonts/08bfcfa5-cfb6-462a-8047-8398a026a60e.eot?#iefix") format("eot")
}

@font-face {
    font-family: "Neue Helvetica W01";
    src: url("../fonts/08bfcfa5-cfb6-462a-8047-8398a026a60e.eot?#iefix");
    src: url("../fonts/08bfcfa5-cfb6-462a-8047-8398a026a60e.eot?#iefix") format("eot"),url("../fonts/aa6913f7-e674-4d61-973b-002422ac447e.woff") format("woff"),url("../fonts/857b0b99-c6b3-4ad8-a049-28506caba502.ttf") format("truetype"),url("../fonts/af9d9c5b-b4f4-4d6f-9809-26fb6a2bb794.svg#af9d9c5b-b4f4-4d6f-9809-26fb6a2bb794") format("svg");
    font-weight: 300;
    font-style: oblique;
}

@font-face {
    font-family: "Neue Helvetica W01_n6";
    src: url("../fonts/83d5bc89-af33-46a9-8fe3-15d87784f50e.eot?#iefix") format("eot")
}

@font-face {
    font-family: "Neue Helvetica W01";
    src: url("../fonts/83d5bc89-af33-46a9-8fe3-15d87784f50e.eot?#iefix");
    src: url("../fonts/83d5bc89-af33-46a9-8fe3-15d87784f50e.eot?#iefix") format("eot"),url("../fonts/102ab74c-0e84-4fe5-a17a-b20fb643591a.woff") format("woff"),url("../fonts/1d146b29-55e2-485b-96aa-5cb628e7e9eb.ttf") format("truetype"),url("../fonts/d90b3358-e1e2-4abb-ba96-356983a54c22.svg#d90b3358-e1e2-4abb-ba96-356983a54c22") format("svg");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Myriad W01_n2";
    src: url("../fonts/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix") format("eot")
}

@font-face {
    font-family: "Myriad W01";
    src: url("../fonts/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix");
    src: url("../fonts/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix") format("eot"),url("../fonts/c5b1c170-d8f7-41f9-85c2-0ab670780c6b.woff") format("woff"),url("../fonts/ba1ff8f6-0c27-4e7b-8be5-818b6c3dd801.ttf") format("truetype"),url("../fonts/a379413e-a0b8-44dc-b250-1e000e4092a4.svg#a379413e-a0b8-44dc-b250-1e000e4092a4") format("svg");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Myriad W01_I2";
    src: url("../fonts/b7dea71a-f8e7-42e0-b03b-ae1b0b6eb0cd.eot?#iefix") format("eot")
}

@font-face {
    font-family: "Myriad W01";
    src: url("../fonts/b7dea71a-f8e7-42e0-b03b-ae1b0b6eb0cd.eot?#iefix");
    src: url("../fonts/b7dea71a-f8e7-42e0-b03b-ae1b0b6eb0cd.eot?#iefix") format("eot"),url("../fonts/7becdf28-cd45-4f8a-bcab-d8c861a8ebc5.woff") format("woff"),url("../fonts/b90a1a2e-a700-4528-aa00-535c93ecf8a9.ttf") format("truetype"),url("../fonts/85c745b1-d826-4e09-988f-82cd152fb0db.svg#85c745b1-d826-4e09-988f-82cd152fb0db") format("svg");
    font-weight: 200;
    font-style: Italic;
}

@font-face {
    font-family: "Myriad W01_n3";
    src: url("../fonts/f71415c0-f68d-42cd-a948-05af81548ea5.eot?#iefix") format("eot")
}

@font-face {
    font-family: "Myriad W01";
    src: url("../fonts/f71415c0-f68d-42cd-a948-05af81548ea5.eot?#iefix");
    src: url("../fonts/f71415c0-f68d-42cd-a948-05af81548ea5.eot?#iefix") format("eot"),url("../fonts/c22866d7-ca67-4170-a113-cae280eea669.woff") format("woff"),url("../fonts/19ce9ea9-076e-4dcd-91a1-454f4830f120.ttf") format("truetype"),url("../fonts/18915124-98e4-4245-9e10-b921e09a6704.svg#18915124-98e4-4245-9e10-b921e09a6704") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Myriad W01_n4";
    src: url("../fonts/4f2af761-c017-4801-9aa5-5d30643373ce.eot?#iefix") format("eot")
}

@font-face {
    font-family: "Myriad W01";
    src: url("../fonts/4f2af761-c017-4801-9aa5-5d30643373ce.eot?#iefix");
    src: url("../fonts/4f2af761-c017-4801-9aa5-5d30643373ce.eot?#iefix") format("eot"),url("../fonts/acfb7490-30f6-4bf4-a1b8-70ff9172d209.woff") format("woff"),url("../fonts/5bf402e3-ba96-414e-bc7e-cb6689fe32c1.ttf") format("truetype"),url("../fonts/c5dceae7-427a-4765-b06b-ea2eecca3434.svg#c5dceae7-427a-4765-b06b-ea2eecca3434") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Myriad W01_n7";
    src: url("../fonts/b4df0776-43d3-4954-9ef2-95527dcfb011.eot?#iefix") format("eot")
}

@font-face {
    font-family: "Myriad W01";
    src: url("../fonts/b4df0776-43d3-4954-9ef2-95527dcfb011.eot?#iefix");
    src: url("../fonts/b4df0776-43d3-4954-9ef2-95527dcfb011.eot?#iefix") format("eot"),url("../fonts/4823eae2-791d-4382-8ef0-5916b5b1623f.woff") format("woff"),url("../fonts/5f217c58-a6c1-4caa-a812-82aebe639d32.ttf") format("truetype"),url("../fonts/d13b5353-c61b-48c5-a822-a1ae523eddf6.svg#d13b5353-c61b-48c5-a822-a1ae523eddf6") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesqueW05-BoldIt';
    src: url('../fonts/BrandonGrotesqueW05-BoldIt.eot');
    src: url('../fonts/BrandonGrotesqueW05-BoldIt.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-BoldIt.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-BoldIt.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-BoldIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'BrandonGrotesqueW05';
    src: url('../fonts/BrandonGrotesqueW05-Light.eot');
    src: url('../fonts/BrandonGrotesqueW05-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-Light.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-Light.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesqueW05-BlackIt';
    src: url('../fonts/BrandonGrotesqueW05-BlackIt.eot');
    src: url('../fonts/BrandonGrotesqueW05-BlackIt.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-BlackIt.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-BlackIt.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-BlackIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'BrandonGrotesqueW05';
    src: url('../fonts/BrandonGrotesqueW05-Black.eot');
    src: url('../fonts/BrandonGrotesqueW05-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-Black.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-Black.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-Black.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesqueW05-LightIt';
    src: url('../fonts/BrandonGrotesqueW05-LightIt.eot');
    src: url('../fonts/BrandonGrotesqueW05-LightIt.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-LightIt.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-LightIt.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'BrandonGrotesqueW05';
    src: url('../fonts/BrandonGrotesqueW05-Medium.eot');
    src: url('../fonts/BrandonGrotesqueW05-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-Medium.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-Medium.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesqueW05';
    src: url('../fonts/BrandonGrotesqueW05-Bold.eot');
    src: url('../fonts/BrandonGrotesqueW05-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-Bold.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-Bold.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-Bold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesqueW05';
    src: url('../fonts/BrandonGrotesqueW05-Thin.eot');
    src: url('../fonts/BrandonGrotesqueW05-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-Thin.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-Thin.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesqueW05-ThinIt';
    src: url('../fonts/BrandonGrotesqueW05-ThinIt.eot');
    src: url('../fonts/BrandonGrotesqueW05-ThinIt.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-ThinIt.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-ThinIt.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-ThinIt.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'BrandonGrotesqueW05-RgIt';
    src: url('../fonts/BrandonGrotesqueW05-RgIt.eot');
    src: url('../fonts/BrandonGrotesqueW05-RgIt.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-RgIt.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-RgIt.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-RgIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'BrandonGrotesqueW05-MdIt';
    src: url('../fonts/BrandonGrotesqueW05-MdIt.eot');
    src: url('../fonts/BrandonGrotesqueW05-MdIt.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-MdIt.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-MdIt.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-MdIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'BrandonGrotesqueW05';
    src: url('../fonts/BrandonGrotesqueW05-Regular.eot');
    src: url('../fonts/BrandonGrotesqueW05-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesqueW05-Regular.woff2') format('woff2'), url('../fonts/BrandonGrotesqueW05-Regular.woff') format('woff'), url('../fonts/BrandonGrotesqueW05-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-BlackItalic.eot');
    src: url('../fonts/BrandonGrotesque-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-BlackItalic.woff2') format('woff2'), url('../fonts/BrandonGrotesque-BlackItalic.woff') format('woff'), url('../fonts/BrandonGrotesque-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-MediumItalic.eot');
    src: url('../fonts/BrandonGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-MediumItalic.woff2') format('woff2'), url('../fonts/BrandonGrotesque-MediumItalic.woff') format('woff'), url('../fonts/BrandonGrotesque-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-BoldItalic.eot');
    src: url('../fonts/BrandonGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-BoldItalic.woff2') format('woff2'), url('../fonts/BrandonGrotesque-BoldItalic.woff') format('woff'), url('../fonts/BrandonGrotesque-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-RegularItalic.eot');
    src: url('../fonts/BrandonGrotesque-RegularItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-RegularItalic.woff2') format('woff2'), url('../fonts/BrandonGrotesque-RegularItalic.woff') format('woff'), url('../fonts/BrandonGrotesque-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-ThinItalic.eot');
    src: url('../fonts/BrandonGrotesque-ThinItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-ThinItalic.woff2') format('woff2'), url('../fonts/BrandonGrotesque-ThinItalic.woff') format('woff'), url('../fonts/BrandonGrotesque-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Light.eot');
    src: url('../fonts/BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Light.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Light.woff') format('woff'), url('../fonts/BrandonGrotesque-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Medium.eot');
    src: url('../fonts/BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Medium.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Medium.woff') format('woff'), url('../fonts/BrandonGrotesque-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Regular.eot');
    src: url('../fonts/BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Regular.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Regular.woff') format('woff'), url('../fonts/BrandonGrotesque-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-LightItalic.eot');
    src: url('../fonts/BrandonGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-LightItalic.woff2') format('woff2'), url('../fonts/BrandonGrotesque-LightItalic.woff') format('woff'), url('../fonts/BrandonGrotesque-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Bold.eot');
    src: url('../fonts/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Bold.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Bold.woff') format('woff'), url('../fonts/BrandonGrotesque-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Thin.eot');
    src: url('../fonts/BrandonGrotesque-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Thin.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Thin.woff') format('woff'), url('../fonts/BrandonGrotesque-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Black.eot');
    src: url('../fonts/BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Black.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Black.woff') format('woff'), url('../fonts/BrandonGrotesque-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Black_1.eot');
    src: url('../fonts/BrandonGrotesque-Black_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Black_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Black_1.woff') format('woff'), url('../fonts/BrandonGrotesque-Black_1.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Light_1.eot');
    src: url('../fonts/BrandonGrotesque-Light_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Light_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Light_1.woff') format('woff'), url('../fonts/BrandonGrotesque-Light_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Bold_1.eot');
    src: url('../fonts/BrandonGrotesque-Bold_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Bold_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Bold_1.woff') format('woff'), url('../fonts/BrandonGrotesque-Bold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Medium_1.eot');
    src: url('../fonts/BrandonGrotesque-Medium_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Medium_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Medium_1.woff') format('woff'), url('../fonts/BrandonGrotesque-Medium_1.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-RegularItalic_1.eot');
    src: url('../fonts/BrandonGrotesque-RegularItalic_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-RegularItalic_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-RegularItalic_1.woff') format('woff'), url('../fonts/BrandonGrotesque-RegularItalic_1.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-BoldItalic_1.eot');
    src: url('../fonts/BrandonGrotesque-BoldItalic_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-BoldItalic_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-BoldItalic_1.woff') format('woff'), url('../fonts/BrandonGrotesque-BoldItalic_1.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Regular_1.eot');
    src: url('../fonts/BrandonGrotesque-Regular_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Regular_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Regular_1.woff') format('woff'), url('../fonts/BrandonGrotesque-Regular_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-LightItalic_1.eot');
    src: url('../fonts/BrandonGrotesque-LightItalic_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-LightItalic_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-LightItalic_1.woff') format('woff'), url('../fonts/BrandonGrotesque-LightItalic_1.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-Thin_1.eot');
    src: url('../fonts/BrandonGrotesque-Thin_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-Thin_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-Thin_1.woff') format('woff'), url('../fonts/BrandonGrotesque-Thin_1.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-BlackItalic_1.eot');
    src: url('../fonts/BrandonGrotesque-BlackItalic_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-BlackItalic_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-BlackItalic_1.woff') format('woff'), url('../fonts/BrandonGrotesque-BlackItalic_1.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-ThinItalic_1.eot');
    src: url('../fonts/BrandonGrotesque-ThinItalic_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-ThinItalic_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-ThinItalic_1.woff') format('woff'), url('../fonts/BrandonGrotesque-ThinItalic_1.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../fonts/BrandonGrotesque-MediumItalic_1.eot');
    src: url('../fonts/BrandonGrotesque-MediumItalic_1.eot?#iefix') format('embedded-opentype'), url('../fonts/BrandonGrotesque-MediumItalic_1.woff2') format('woff2'), url('../fonts/BrandonGrotesque-MediumItalic_1.woff') format('woff'), url('../fonts/BrandonGrotesque-MediumItalic_1.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

