@import 'mixins.scss';
@import 'variables.scss';

.jl-PandPBenefits {

    .row {
        @media(min-width: $sm) {
            margin-left: 0px;
        }
    }

    font-family: $brandy;

    .subheader {
        text-align: left;
        margin-left: 20px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        color: #FFFFFF;
        margin-top: 116px;
        letter-spacing: 3px;

        @media(min-width: $md) {
            text-align: center;
            margin-left: 0px;
        }
    }

    .nav-link {
        @media(max-width: $sm) {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    .header {
        text-align: left;
        font-size: 50px;
        color: #FFFFFF;
        margin-top: 16px;
        margin-left: 20px;
        margin-right: 60px;
        margin-bottom: 45px;
        font-weight: bold;

        @media(min-width: $md) {
            text-align: center;
            margin-right: 0px;
        }
    }

    #pills-tab {
        font-size: 15px;
        overflow-x: auto;
        white-space: nowrap;
    }

    #pills-tab {
        flex-wrap: nowrap !important;
    }


    .benefitsContainer {
        max-width: 1200px;
        margin: 0 auto;
    }

    .tab-content {
        margin-top: 61px;
        margin-bottom: 46px;
    }

    .nav-link.active {
        background-color: transparent;
        border-bottom: 3px solid #CCB072;
        padding-bottom: .4rem;
    }

    .nav-link {
        color: #FFFFFF;
        text-decoration: none;
        border-bottom: 1px solid #FFFFFF;
        letter-spacing: 3px;
        font-weight: bold;
    }

    .nav-pills .nav-link {
        border-radius: 0px;
    }

    .detailsHeader {
        font-size: 24px;
        font-weight: bold;
        background-repeat: no-repeat;
        background-size: 39px 39px;
        padding-bottom: 15px;
    }

    .detailsHeaderIcon {
        width: 39px;
        height: 39px;
        margin-bottom: 15px;
        background-color: #B09863;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 55%;
        border: 1px solid #B09863;
        border-radius: 50%;

        @media(min-width: $md) {
            margin-left: 15px;
        }

        @media(max-width: $lg) {
            margin-right: 10px;
        }
    }

    .details {
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 500;
        font-style: normal;
    }

    .detailTabs {
        max-width: 300px;
        margin: 0 auto;
    }
}
