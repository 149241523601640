@import 'mixins.scss';
@import 'variables.scss';

.jl-more {
    background: #e0e0e0;
    color: #000;
    padding: 30px 26px;

    @media(min-width:$sm) {
        padding: 100px 26px;
    }

    @media(max-width:$sm) {
        .jlmc {
            margin-top: 30px;
        }

        .brake-image {
            display: none;
        }

        .find {
            margin-bottom: 20px;
            display: block;
        }
    }

    @media(max-width:$md) {
        .brake-image {
            display: none;
        }
    }


    img.jlmc {
        max-width: 168px;
        margin-bottom: 25px;
    }

    .header {
        font-family: 'DharmaGothicM-ExBold';
        font-size: 67px;
        line-height: 70px !important;
        text-transform: uppercase;
        line-height: .8;
        margin-bottom: 24px;
        letter-spacing: 3px;
    }

    p, a {
        font-family: $brandy;
        font-weight: 500;
        font-style: normal;
        font-size: 17px;
    }

    .brake-image {
        background: url('../images/jl-brake.png') center no-repeat;
        @include background-size(contain);
        width: 100%;
        max-width: 850px;
        position: relative;
        margin-top: 0;

        @media screen and (min-width: $md) {
            margin-top: -175px;
        }

        img {
            width: 100%;
            visibility: hidden;
        }
    }
}
