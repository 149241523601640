@import 'mixins.scss';
@import 'variables.scss';

.jl-HIWqAndA {
    background-color: #F6F6F6;
    font-family: $brandy;

    .subheader {
        text-align: center;
        color: #B09863;
        font-size: 13px;
        padding-top: 80px;
        margin-bottom: 16px;
        letter-spacing: 3px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .header {
        color: #343737;
        text-align: center;
        font-size: 50px;
        margin-bottom: 57px;
        font-weight: bold;
        font-weight: bold;
    }

    .card {
        border: 0px;
        border-bottom: 1px solid #979797;
        background-color: transparent;
    }

    .card-header {
        background-color: transparent;
        border: 0px;
        font-weight: 500 !important;
        font-style: normal;
        font-size: 18px;
    }

    .btn-link {
        color: #343737;
        font-weight: 500 !important;
    }

    .card-body {
        color: #343737;
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        margin-left: 12px;
    }

    #accordion {
        margin-bottom: 100px;
    }

    .btn-link {
        display: block;
        width: 100%;
        text-align: left;
        background: url('../images/QandAarrow.png') no-repeat;
        background-position: right;
        overflow: hidden;
        white-space: normal;
        padding-right: 40px;

        @media(min-width: $md) {
            padding-right: 0px;
        }
    }

    .btn-link[aria-expanded="true"] {
        background: url('../images/QandAarrowDown.png') no-repeat;
        background-position: right;
    }

    .qaQuestion {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.2;
    }
}
