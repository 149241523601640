@import 'variables.scss';

.nowrap {
    white-space: nowrap;
}

.navWrapper {
    position: fixed;
    width: 100%;
    height: 77px;
    -webkit-transition: background-color .3s linear; /* Saf3.2+, Chrome */
    -moz-transition: background-color .3s linear; /* FF3.7+ */
    -o-transition: background-color .3s linear; /* Opera 10.5 */
    transition: background-color .3s linear;
    z-index: 1029;
    background-color: #343737;
}

.navbar {
    font-family: $brandy;
    width: 96vw;
    padding: 16px 24px;
    border-bottom: 1px solid transparent;
    margin: 0 auto;

    a.skip-main {
        left: -999px;
        position: absolute;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
        z-index: -999;
    }

    a.skip-main:focus, a.skip-main:active {
        color: #fff;
        background-color: transparent;
        left: auto;
        top: auto;
        width: 30%;
        height: auto;
        overflow: auto;
        margin: 10px 15%;
        padding: 5px;
        text-align: center;
        font-size: 1.2em;
        z-index: 999;
    }

    .navbar-nav {
        text-align: center;
        margin-top: 5vh;

        @media(min-width: $lg) {
            margin-top: 0;
        }
    }

    @media(min-width: $lg) {
        width: 98vw;
        padding-left: 40px;
        padding-right: 40px;
    }

    &.navbar-border {
        border-bottom: 1px solid #fff;
    }

    &.navbar-dark {
        .navbar-toggler {
            position: relative;
            top: -5px;
            border: none;
            background: transparent !important;
            padding: 10px;
            margin-right: 3%;
            z-index: 10;

            &:hover {
                background: transparent !important;
            }

            .icon-bar {
                background-color: #FFFFFF;
                transition: all 0.2s;
                display: block;
                width: 30px;
                height: 2px;
                margin-top: 6px;
            }

            .top-bar {
                transform: rotate(45deg);
                transform-origin: 10% 10%;
            }

            .middle-bar {
                opacity: 0;
            }

            .bottom-bar {
                transform: rotate(-45deg);
                transform-origin: 10% 90%;
            }

            &.collapsed {
                z-index: 100;

                .top-bar {
                    transform: rotate(0);
                }

                .middle-bar {
                    opacity: 1;
                }

                .bottom-bar {
                    transform: rotate(0);
                }
            }
        }

        .nav-link {
            padding: 0;
            margin-top: 30px;

            @media(min-width:$md) {
                margin-top: 0px;
            }
        }

        .jl-logo {
            background-image: url('/images/jiffylube-logo.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            width: 165px;
            height: 44px;
        }

        .collapse,
        .collapsing {
            width: 85vw;
            text-align: right;
            padding: 24px;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 100vw;
            background: url('../images/jl-extended.svg') no-repeat;
            background-size: 180% 180%;
            background-position: 10% 60%;
            background-color: $jl-red;
            -webkit-transition: .3s linear; /* Saf3.2+, Chrome */
            -moz-transition: .3s linear; /* FF3.7+ */
            -o-transition: .3s linear; /* Opera 10.5 */
            transition: .3s linear;

            @media(min-width:$md) {
                width: 45vw;
            }

            @media(min-width:$lg) {
                background: none;
                height: 83px;
                width: 68vw;
                padding: 24px;
                position: inherit;
                top: 0px;
                left: 29vw;
            }
        }

        .collapse.show {
            left: 15vw;

            @media(min-width:$md) {
                left: 55vw;
            }
        }

        &.navbar-expand-lg, &.navbar-expand-md {
            .nav-item {
                padding: 10px 0 7px;
            }

            .nav-link {
                display: inline-block;
                color: #fff;
                font-size: 19px;
                font-weight: bold;
                letter-spacing: 2px;
                margin-left: 0px;
                text-decoration: none;
                border-bottom: 3px solid transparent;
                -webkit-transition: border-color .3s linear; /* Saf3.2+, Chrome */
                -moz-transition: border-color .3s linear; /* FF3.7+ */
                -o-transition: border-color .3s linear; /* Opera 10.5 */
                transition: border-color .3s linear;

                @media(min-width:$md) {
                    margin-left: 30px;
                }

                @media(min-width:$lg) {
                    font-size: 13px;
                }

                &.active, &:hover {
                    border-color: $jl-gold;
                }

                &.nav-link-cta {
                    background: $jl-red;
                    border-radius: 3px;
                    margin-left: 15px;
                }
            }

            .apply-now {
                display: inline-block;
                color: #343737;
                background: #fff;
                border-radius: 5px;
                text-transform: uppercase;
                font-size: 16px;
                letter-spacing: 2.17px;
                padding: 23px 69px;
                text-decoration: none;
                font-weight: bold;
                margin-top: 20vh;

                @media(min-width:$md) {
                    margin-top: 60vh;
                }

                @media(min-width:$lg) {
                    background: #862633;
                    color: #FFFFFF;
                    padding: 12px 26px 10px;
                    font-size: 13px;
                    font-weight: bold;
                    margin-top: -7px;
                    margin-right: 0px;
                    align-items: baseline;
                }

                &:hover {
                    border-color: transparent;
                }
            }
        }
    }
}
