@import 'variables.scss';
.tabs {
    .tablist {
        margin: 0 auto;
        text-align: center;
        border-bottom: 2px solid #ffffff
    }

    .row {
        @media(min-width: $sm) {
            margin-left: 0px;
        }
    }

    font-family: $brandy;

    .subheader {
        text-align: left;
        margin-left: 20px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        color: #FFFFFF;
        margin-top: 116px;
        letter-spacing: 3px;

        @media(min-width: $md) {
            text-align: center;
            margin-left: 0px;
        }
    }

    .header {
        text-align: left;
        font-size: 50px;
        color: #FFFFFF;
        margin-top: 16px;
        margin-left: 20px;
        margin-right: 60px;
        margin-bottom: 45px;
        font-weight: bold;

        @media(min-width: $md) {
            text-align: center;
            margin-right: 0px;
        }
    }

    .tab-content {
        margin-top: 61px;
        margin-bottom: 46px;
    }

    * {
        box-sizing: border-box;
    }
    /**
  Tab button controls 1
*/
    .tab {
        font-size: 15px;
        letter-spacing: 3px;
        font-weight: bold;
        width: 25%;
        color: #FFFFFF;
        background: 0;
        background-color: transparent;
        opacity: .5;
        border-left: transparent;
        border-right: transparent;
        border-top: transparent;
    }
    /** Active state */
    .tab:active {
        outline: none;
    }
    /** Selected state (tabpanel visible) */
    .tab[aria-selected="true"] {
        opacity: 1;
        border-bottom: 3px solid #CCB072;
    }

    .tab[aria-selected="true"]:focus {
        outline: none;
    }
    /** Disabled state */
    .tab[disabled="true"] {
        cursor: not-allowed;
        text-decoration: line-through;
    }
    /**
  Tab panels
*/
    .tabpanel {
        display: none;
        padding: 20px;
        max-width: 1120px;
        margin: 0 auto;
    }

    .tabpanel.is-visible {
        display: block;
    }

    .tabpanel h2 {
        margin-top: 0;
    }

    .detailsHeader {
        font-size: 24px;
        font-weight: bold;
        background-repeat: no-repeat;
        background-size: 39px 39px;
        padding-bottom: 15px;
    }

    .detailsHeaderIcon {
        width: 39px;
        height: 39px;
        margin-bottom: 15px;
        background-color: #B09863;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 55%;
        border: 1px solid #B09863;
        border-radius: 50%;

        @media(min-width: $md) {
            margin-left: 15px;
        }

        @media(max-width: $lg) {
            margin-right: 10px;
        }
    }

    .details {
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 500;
        font-style: normal;
    }

    .detailTabs {
        max-width: 300px;
        margin: 0 auto;
    }
}
