@import 'mixins.scss';
@import 'variables.scss';


.jl-PandPMeetThePeople {
    padding-top: 82px;
    width: 96%;
    margin: 0 auto;
    font-family: $brandy;

    @media(min-width: $lg) {
        width: inherit;
        margin: 0;


        .people-bg {
            background: url('../images/flyingJ.svg') no-repeat;
            background-position: bottom;
            background-color: #343737;
        }
    }

    .people-container {
        padding: 0 15px 16px;
        width: 100%;
        margin-top: 30px;

        @media(min-width: $lg) {
            margin: 0;
            max-width: 415px;
            float: right;
        }
    }

    .tab-content {
        margin: 0 1%;
        width: 98%;

        @media(min-width: $lg) {
            width: 100%;
            margin: 0;
        }
    }

    .mobileOnly {
        display: none;

        @media(max-width: $md) {
            display: initial;
        }
    }


    .subheader {
        text-align: left;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        color: #FFFFFF;
        letter-spacing: 3px;
        margin-top: 16px;

        @media(min-width: $lg) {
            margin-top: 40px;
        }
    }

    .header {
        font-family: $dharma-exbold;
        text-align: left;
        font-size: 68px;
        color: #FFFFFF;
        letter-spacing: 1px;
        line-height: 1.2;
        margin-bottom: 24px;

        @media(min-width: $lg) {
            font-size: 81px;
            margin-bottom: 40px;
        }
    }

    #pills-tab {
        font-size: 15px;
    }

    .nav-item {
        width: 32%;
        text-align: center;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 10px;

        @media(min-width: $lg) {
            display: block;
            text-align: left;
            width: 100%;
            margin-top: 20px;
            border-bottom: solid 1px #979797;
            /*margin-bottom: 31px;
            padding: 0 0 35px;*/
            &:last-child {
                border: 0;
            }
        }
    }

    .nav-link {
        display: block;
        color: #FFFFFF;
        text-decoration: none;
        font-size: 12px;
        font-weight: bold;
        padding: 0;
        position: relative;

        .linkText {
            color: #E3C37F;
            letter-spacing: 1.5px;
            line-height: 1.1;

            @media (min-width: $lg) {
                letter-spacing: 2px;
                line-height: initial;
            }
        }

        .linkNote {
            color: #FFFFFF;
            line-height: 24px;
            font-weight: 500;
        }

        @media(min-width: $lg) {
            font-size: 12px;
        }

        .thumpnailsPeps {
            width: 70px;
            height: 70px;
            border: 3px solid #FFFFFF;
            border-radius: 50%;
            margin-bottom: 16px;

            @media(min-width: $lg) {
                margin-bottom: 0px;
            }
        }

        &.active {
            .thumpnailsPeps {
                border: 3px solid #E3C37F;
            }

            .linkText {
                color: #FFFFFF;
            }

            .linkNote {
                color: #E3C37F;
            }

            background-color: transparent;
            color: #FFFFFF;

            @media(min-width: $lg) {
                .activeArrow:after {
                    font-family: 'Material Icons';
                    font-weight: normal;
                    font-style: normal;
                    font-size: 40px;
                    color: #E3C37F;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    font-feature-settings: "liga" 1;
                    -webkit-font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                    position: relative;
                    bottom: 95px;
                    border: 0;
                    width: 26px;
                    height: 48px;
                    content: "keyboard_arrow_right";
                    float: right;
                }
            }
        }

        img {
            display: block;
            margin: 0 auto;

            @media(min-width: $lg) {
                margin: 0;
                display: inline-block;
                margin-right: 25px;
            }
        }
    }

    .nav {
        display: block;
    }

    .detailsHeader {
        font-size: 24px;
        font-weight: bold;
        background-repeat: no-repeat;
        background-size: 39px 39px;
        padding-left: 60px;
        padding-bottom: 15px;
    }

    .details {
        font-size: 16px;
        padding-left: 60px;
    }

    .detailTabs {
        max-width: 300px;
        margin: 0 auto;
    }

    .quoteSource {
        color: #343737;
        letter-spacing: 3px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 900;
        font-style: normal;
    }

    .quoteSource::before {
        content: "\a -";
        white-space: pre;
    }

    .profile-img {
    }

    .quote {
        background-color: #B09863;
        padding: 20px 16px;
        bottom: 0px;
        width: 100%;
        font-size: 1.375em;
        line-height: 31px;

        @media(min-width: $md) {
            width: inherit;
            margin-right: 144px;
        }

        @media(min-width: $lg) {
            position: absolute;
            padding: 32px;
            font-size: 32px;
            font-weight: 300;
            line-height: 38px;
            max-width: 680px;
            border-right: solid 15px #fff;
        }
    }

    .linkText {
        position: relative;
        bottom: 0px;

        @media(min-width: $md) {
            position: relative;
            bottom: 0px;
        }

        @media(min-width: $lg) {
            position: relative;
            bottom: 20px;
        }
    }

    .linkNote {
        display: none;
        margin-left: 100px;
        margin-right: 40px;
        position: relative;
        bottom: 40px;
        font-size: 20px;

        @media(min-width: $md) {
            display: none;
        }

        @media(min-width: $lg) {
            display: block;
        }
    }
}