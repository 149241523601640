﻿/* Breakpoints */
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

/* Fonts */
$myriad: "Myriad W01", "Neue Helvetica W01", sans-serif;
$dharma: "DharmaGothicM-Regular", sans-serif;
$dharma-bold: "DharmaGothicM-Bold", sans-serif;
$dharma-exbold: "DharmaGothicM-ExBold", sans-serif;
$brandy: "Brandon Grotesque", sans-serif;
/* Colors */
$jl-red: #862633;
$jl-gold: #B09863;
