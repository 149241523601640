@import 'mixins.scss';
@import 'variables.scss';

.jl-extended {
    margin-top: -25vh;
    position: relative;
    text-align: center;
    z-index: 3;

    @media(max-width:$sm) {
        margin-top: -25vh;
    }

    @media(max-width:$md) {
        margin-top: -45vh;
    }

    .container {
        background: $jl-red url('../images/jl-extended.svg') no-repeat;
        background-position: bottom left;
        background-size: 100% 100%;
        padding: 24px 8px 24px 8px;
        box-shadow: 10px 60px 64px -40px rgba(0,0,0,0.82);
        border-radius: 4px;

        @media(min-width:$sm) {
            padding: 40px 64px 32px 64px;
        }

        @media(min-width:$lg) {
            padding: 100px 150px 32px 150px;
        }
    }

    .subheader {
        font-family: $brandy;
        font-weight: 900;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 25px;
        margin-top: 32px;
    }

    .header {
        font-family: $dharma-bold;
        font-size: 54px;
        text-transform: uppercase;
        line-height: .8;
        margin-bottom: 18px;
        letter-spacing: 3px;

        @media(min-width:$md) {
            font-size: 84px;
        }
    }

    p {
        font-family: $brandy;
        font-size: 22px;
        color: #F2F2F2;
        letter-spacing: 0;
        text-align: center;
        line-height: 34px;
        font-weight: 300;
        font-style: normal;
        margin: 0 0 54px;
        padding: 0 18px;

        @media(min-width:$md) {
            font-size: 26px;
            line-height: 44px;
        }
    }

    .source {
        font-family: $brandy;
        font-size: 12px;
        color: #F2F2F2;
        margin-top: 0px;

        @media(min-width:$md) {
            margin-top: 90px;
        }
    }
}
