@import 'variables.scss';

footer {
    background: $jl-red;
    text-align: center;

    @media(min-width: $md) {
        text-align: left;
    }


    a, a:link, a:active, a:visited, a:hover {
        color: #fff;
        font-size: 14px;
    }

    li.list-inline-item {
        padding: 0 15px 0 0;
        border-right: 1px solid #fff;
        line-height: 14px;


        &:last-child {
            border-right: none;
        }
    }

    .container-fluid {
        border-top: 1px solid #fff;
        width: 90%;
        padding-top: 26px;

        @media(min-width:$md) {
            padding-top: 44px;
        }
    }

    .col-lg-6, .col-lg-12 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .disclaimer {
        font-size: 14px;
        text-align: center;

        @media(min-width:$md) {
            text-align: right;
        }
    }

    p{font-size:12px;
      line-height:16px;
    }
}
