@import 'mixins.scss';
@import 'variables.scss';
$slideHeightMobile: 800px;
$slideHeightSmall: 700px;
$slideHeightMedium: 450px;
$slideHeightLarge: 700px;

.jl-PandPslides {
    width: 100%;
    height: $slideHeightMobile;
    color: #343737;
    font-family: $brandy;

    @media(min-width: $sm) {
        height: $slideHeightSmall;
    }

    @media(min-width: $md) {
        height: $slideHeightMedium;
    }

    @media(min-width: $lg) {
        height: $slideHeightLarge;
    }

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .slick-dots {
        position: absolute;
        bottom: 620px;

        @media(min-width: $md) {
            bottom: 250px;
        }

        @media(min-width: $lg) {
            bottom: 24px;
        }

        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;

        li {
            position: relative;
            display: inline-block;
            width: 15px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                background: 0 0;
                display: block;
                height: 12px;
                font-size: 0;
                color: #D8D8D8;
                opacity: 1;
                border: solid 1px #B09863;
                background-color: transparent;
                border-radius: 50%;
                cursor: pointer;
            }

            &.slick-active button {
                opacity: 1;
                background-color: #B09863;
            }
        }
    }

    .row {
        margin-bottom: 20px;
        width: 100%;
        background-color: #E0E0E0;
    }

    .fist {
        .wrapper {
            margin: 0 auto;
        }

        @media(min-width:$lg) {
            background-image: url('/images/hand-wrenches.png');
            background-repeat: no-repeat;
            background-position: right;
            background-size: 45%;

            .wrapper {
                width: 65%;
                margin: 0;

                @media(min-width: 1200px) {
                    width: 55%;
                }
            }

            .text {
                margin: 0 auto;
                width: 458px;
            }
        }
    }

    .yourBusiness {
        .wrapper {
            margin: 0 auto;
        }


        @media(min-width:$lg) {
            .img {
                height: 700px;
                width: 42%;
                float: right;
                background-image: url('/images/slider/atTheComputer.jpg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }

            .wrapper {
                display: inline-block;
                width: 58%;
                margin: 0;

                @media(min-width: 1200px) {
                    width: 55%;
                }
            }

            .text {
                margin: 0 auto;
                width: 401px;
            }
        }
    }

    .fleetsImg {
        .wrapper {
            margin: 0 auto;
        }

        @media(min-width:$lg) {
            background-image: url('/images/fleetsImg.png');
            background-repeat: no-repeat;
            background-position: right;
            background-size: 50%;

            .wrapper {
                margin: 0;
                width: 68%;

                @media(min-width: 1200px) {
                    width: 55%;
                }
            }

            .text {
                margin: 0 auto;
                width: 447px;
            }

            p {
                width: 356px;
            }
        }
    }

    .store {
        .wrapper {
            margin: 0 auto;
        }

        @media(min-width:$lg) {
            .img {
                height: 700px;
                width: 40%;
                float: right;
                background-image: url('/images/JLMCStore.jpg');
                background-repeat: no-repeat;
                background-size: cover;
            }

            .wrapper {
                display: inline-block;
                width: 60%;
                margin: 0;
            }

            .text {
                margin: 0 auto;
                width: 411px;
            }
        }
    }


    &::after {
        content: "";
        display: block;
        height: 40vh;
        width: 100%;
    }

    .slick-slide {
        height: $slideHeightMobile;

        @media(min-width: $sm) {
            height: $slideHeightSmall;
        }

        @media(min-width: $md) {
            height: $slideHeightMedium;
        }

        @media(min-width: $lg) {
            height: $slideHeightLarge;
        }

        .subheader {
            margin-top: 180px;
            margin-bottom: 16px;
            font-size: 13px;
            color: #B09863;
            text-transform: uppercase;
            letter-spacing: 3px;
            font-weight: bold;
        }

        .header {
            margin-bottom: 22px;
            font-size: 36px;
            line-height: 44px;
            margin-bottom: 16px;
            font-weight: bold;

            @media(min-width: $lg) {
                font-size: 44px;
                margin-bottom: 22px;
            }
        }

        p {
            font-size: 17px;
            margin: 0;
            padding: 0;
            font-weight: bold;
            line-height: 29px;
        }
    }

    .slick-prev,
    .slick-next {
        border: 0;
        border-radius: 50px;
        width: 53px;
        height: 53px;
        top: calc(50% - 50px);
        right: calc(50% - 50px);
        z-index: 1;
        position: absolute;
        background-color: #B09863;
        color: transparent;
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        margin: 2%;

        &:before {
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 44px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: "liga" 1;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
            top: 3px;
            border: 0;
            width: 53px;
            height: 53px;
            color: #fff;
        }
    }

    .slick-prev {
        left: 0;

        &:before {
            content: "keyboard_arrow_left";
        }
    }

    .slick-next {
        right: 0;

        &:before {
            content: "keyboard_arrow_right";
        }
    }

    .slick-prev::before {
        position: relative;
        right: 4px !important;
    }

    .slick-next::before {
        position: relative;
        right: 6px !important;
    }

    @media(max-width:$md) {
        .slick-slide .subheader {
            margin-top: 60px;
        }
    }

    @media(max-width:$sm) {
        .fist, .yourBusiness, .fleetsImg {
            background-size: 0 0;
        }


        .slick-slide .subheader {
            margin-top: 30px;
        }

        $size: 40px;

        .slick-prev, .slick-next {
            border-radius: 1px;
        }

        .slick-prev {
            border-bottom-right-radius: $size * 2;
            border-top-right-radius: $size * 2;
        }

        .slick-next {
            border-bottom-left-radius: $size * 2;
            border-top-left-radius: $size * 2;
        }

        .slick-prev::before {
            font-size: 44px;
            position: relative;
            right: 18px !important;
        }

        .slick-next::before {
            font-size: 44px;
            position: relative;
            right: 13px !important;
        }

        .slick-prev, .slick-next {
            height: 55px;
            width: 30px;
            top: calc(50% - 50px);
            z-index: 1;
            position: absolute;
            background-color: #B09863;
            color: transparent;
            line-height: 0;
            font-size: 0;
            cursor: pointer;
            margin: 0;

            &:before {
                font-family: 'Material Icons';
                font-weight: normal;
                font-style: normal;
                font-size: 50px;
                line-height: 1;
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                font-feature-settings: "liga" 1;
                -webkit-font-feature-settings: 'liga';
                -webkit-font-smoothing: antialiased;
                top: -5px;
                width: 40px;
                height: 40px;
                color: #fff;
            }

            .slick-prev {
                left: 15px !important;
            }
        }
    }
}
