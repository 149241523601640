@import 'variables.scss';
#appHeader {
    background-color: $jl-red;
    padding-top: 20px;
    padding-bottom: 20px;

    a {
        color: #FFFFFF
    }

    .logoHolder {
        max-width: 200px;
    }

    .closeOut {
        float: right;
        position: relative;
        top: 3px;
        font-size: 25px;
    }
}