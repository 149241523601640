@import 'mixins.scss';
@import 'variables.scss';

.jl-stats {
    margin-top: 60px;
    margin-bottom: 50px;
    text-align: center;

    @media(min-width:$md) {
        margin-top: 150px;
        margin-bottom: 90px;
    }

    .container {
        background: url('../images/us-map.svg') no-repeat;
        background-position: 50% 0%;
        @include background-size(contain);
        padding-bottom: 55px;
        color: #e0e0e0;

        @media(min-width: $md) {
            padding-bottom: 150px;
        }
    }

    .subheader {
        font-family: $brandy;
        font-weight: bold;
        font-style: normal;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 25px;
    }

    .header {
        font-family: $brandy;
        font-weight: bold;
        font-style: normal;
        font-size: 44px;
        line-height: 50px;
    }

    .numbers {
        font-family: $dharma-bold;
        font-size: 200px;
        color: $jl-gold;
        line-height: 179px;
        margin-top: 32px;

        @media(min-width: $sm) {
            line-height: 1.2;
            margin-top: 0px;
        }
    }

    .text {
        font-family: $brandy;
        font-weight: 900;
        font-style: normal;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
    }
}
