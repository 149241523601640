@import 'mixins.scss';
@import 'variables.scss';

.jl-contact {
    background: $jl-red;
    padding: 34px 0;
    text-align: center;
    font-family: $brandy;
    font-weight: bold;

    @media(min-width:$md) {
        padding: 75px 0;
    }

    .subheader {
        font-family: $brandy;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 25px;
    }

    .jl-feedback-txtfield:focus {
        background: rgba(255, 255, 255, 0.15);
    }

    input[type=checkbox] {
        border-radius: 4px;
        background: #fff;
        border: 1px solid #979797;
        -webkit-appearance: none;
        height: 18px;
        width: 18px;
        padding: 0;
        margin: 0;
        position: relative;
        top: 3px;
        right: 3px;

        &:checked {
            background: #fff;
            border: none;

            &:after {
                font-family: 'Material Icons';
                content: 'check';
                color: #862633;
                font-size: 15px;
                font-weight: bold;
                position: absolute;
                left: 2px;
                bottom: -3px;
            }
        }
    }

    input,
    textarea {
        background: #7D232F;
        border: 1px solid #979797;
        border-radius: 0;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        max-width: 500px;
        padding: 16px 16px 14px;




        @include input-placeholder {
            color: #fff;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }

    textarea {
        min-height: 125px;
    }

    button {
        background: #fff;
        color: #6e6e6e;
        border: none;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 10px 30px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
    }

    #msg {
        display: block;
        font-weight: normal;
        font-style: normal;
        font-size: 18px;
        max-width: 500px;
        margin: 0 auto;
        margin-top: 20px;
        color: #ffd500;
        text-transform: uppercase;
    }

    label {
        display: block;
        text-align: left;
        max-width: 500px;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .telNo {
        a {
            color: #fff;
        }
    }

    .OptInCommsContainer {
        font-size: 14px;
        a {
            color: #fff;
        }


        .OptInCommsLabel {
            display: inline-block;
        }
    }
}
