@import 'mixins.scss';
@import 'variables.scss';

.congrats {
    color: #000000;
    background-color: #f8f8f8;
    height: 1000px;
    text-align: center;
    font-family:$brandy;

    .header {
        margin-top: 215px;
        font-size: 50px;
        font-weight: bold;
    }

    .subheader {
        margin-top: 20px;
        margin-bottom: 250px;
        font-size: 17px;
        font-weight: 500;
        padding-left: 15%;
        padding-right: 15%;
        line-height:30px;
    }
}
